import {
  Array,
  Boolean,
  Dictionary,
  Literal,
  Null,
  Number,
  Optional,
  Record as RRecord,
  Static,
  String,
  Union,
  Unknown,
} from "runtypes";

import { ChartSpec } from "./chart/types.js";
import {
  RelativeDateOperationLiteral,
  RelativeDateUnitLiteral,
} from "./datetimeType.js";
import {
  BinaryColumnPredicateOpLiteral,
  ListBinaryColumnPredicateOpLiteral,
  UnaryColumnPredicateOpLiteral,
} from "./display-table/columnPredicateTypes.js";
import { TableFiltersDefinition } from "./display-table/filterTypes.js";
import {
  DisplayTableOutput,
  SortDirectionLiteral,
} from "./display-table/outputTypes.js";
import { CellType, MagicTypeAheadProviderLiteral } from "./enums.js";
import {
  ExploreChartConfig,
  ExploreDetailField,
  ExploreField,
} from "./explore/types.js";
import { AppFeatureFlagRuntype } from "./FeatureFlag.js";
import { HqlAggregationFunctionLiteral } from "./hql/types.js";
import {
  CellId,
  DataConnectionId,
  DataSourceTableId,
  MagicEventId,
} from "./idTypeBrands.js";
import { AnthropicModelParams } from "./magic/anthropicTypes.js";
import { AugmentModelConfig } from "./magic/augmentTypes.js";
import { CodeiumModelConfig } from "./magic/codeiumTypes.js";
import { OpenAiModelParams } from "./magic/openAiTypes.js";
import { getNormalEnum } from "./runtypeEnums";
import { ExploreCellDataframe } from "./sql/dataSourceTableConfig.js";
import { VariableName } from "./typeBrands.js";

export const SupportedExploreIntentLiteral = Union(
  Literal("SUPPORTED_EXPLORATION_EDIT"),
  Literal("SUPPORTED_EXPLORATION_GENERATION"),
);
export const UnsupportedIntentLiteral = Union(
  Literal("UNSUPPORTED_EXPLORATION_GENERATION"),
  Literal("QUESTION"),
  Literal("OTHER_COMMAND"),
  Literal("VISUAL_DETAIL_EDIT"),
  Literal("LIMIT"),
  Literal("NONSENSE"),
  Literal("UNSUPPORTED_VISUALIZATION"),
);
export const ExploreIntentLiteral = Union(
  ...SupportedExploreIntentLiteral.alternatives,
  ...UnsupportedIntentLiteral.alternatives,
);
export type ExploreIntent = Static<typeof ExploreIntentLiteral>;
export const ExploreIntent = getNormalEnum(ExploreIntentLiteral);

export const PromptTemplateTypeLiteral = Union(
  Literal("APPEND_MARKDOWN"),
  Literal("APPEND_PYTHON"),
  Literal("APPEND_R"),
  Literal("COMPLETE_MARKDOWN"),
  Literal("COMPLETE_PYTHON"),
  Literal("COMPLETE_R"),
  Literal("COMPLETE_SQL"),
  Literal("DOCUMENT_PYTHON"),
  Literal("DOCUMENT_R"),
  Literal("DOCUMENT_SQL"),
  Literal("EDIT_EXPLORE"),
  Literal("EDIT_EXPLORE_SEMANTIC"),
  Literal("EDIT_MARKDOWN"),
  Literal("EDIT_PYTHON"),
  Literal("EDIT_PYTHON_DIFF"),
  Literal("EDIT_R"),
  Literal("EDIT_SQL"),
  Literal("EDIT_SQL_DIFF"),
  Literal("EXPERIMENT"),
  Literal("GENERATE_EXPLORE"),
  Literal("GENERATE_EXPLORE_SEMANTIC"),
  Literal("SUMMARIZE_EXPLORE"),
  Literal("SUMMARIZE_EXPLORE_NO_OUTPUT_DATA"),
  Literal("FIX_MARKDOWN"),
  Literal("FIX_PYTHON"),
  Literal("FIX_PYTHON_BASIC"),
  Literal("FIX_PYTHON_DIFF"),
  Literal("FIX_R"),
  Literal("FIX_SQL"),
  Literal("FIX_SQL_DIFF"),
  Literal("FIX_SQL_BASIC"),
  Literal("GENERATE_MARKDOWN"),
  Literal("GENERATE_PYTHON"),
  Literal("GENERATE_R"),
  Literal("GENERATE_SQL"),
  Literal("GENERATE_EXAMPLE_PROMPTS"),
  Literal("NAME_SQL_CELL"),
  Literal("NAME_PYTHON_CELL"),
  Literal("NAME_R_CELL"),
  Literal("NAME_MARKDOWN_CELL"),
  Literal("SPLIT_CTES"),
  Literal("GENERATE_PROJECT_DESCRIPTION"),
  Literal("GENERATE_PROJECT_HYDE"),
  Literal("NAME_PROJECT"),
  Literal("INFER_MODE_AND_CHAIN_TEMPLATE"),
  Literal("INFER_MODE_AND_CHAIN_TEMPLATE_NO_DATAFRAMES"),
  Literal("GENERATE_CHART"),
  Literal("SIMPLE_CHART"),
  Literal("EDIT_CHART"),
  Literal("NAME_CHART_CELL"),
  Literal("PLAN_CHAIN_SQL_CHART"),
  Literal("PLAN_CHAIN_SQL_SQL_CHART"),
  Literal("PLAN_CHAIN_SQL_CODE_CHART"),
  Literal("PLAN_CHAIN_CODE"),
  Literal("PLAN_CHAIN_DYNAMIC"),
  Literal("PLAN_CHAIN_CHART"),
  Literal("PLAN_CHAIN_SQL"),
  Literal("PLAN_CHAIN_CODE_CHART"),
  Literal("SUMMARIZE_CHAIN"),
  Literal("SUMMARIZE_CELL"),
  Literal("INFER_EXPLORE_INTENT"),
);
export type PromptTemplateType = Static<typeof PromptTemplateTypeLiteral>;
export const PromptTemplateType = getNormalEnum(PromptTemplateTypeLiteral);

export const MentionedDataframeName = String.withBrand(
  "MentionedDataframeName",
);
export type MentionedDataframeName = Static<typeof MentionedDataframeName>;

export const MagicCellPayload = Union(ChartSpec);
export type MagicCellPayload = Static<typeof MagicCellPayload>;

export const MagicCellChainTemplateTypeWithoutChartOnlyLiteral = Union(
  Literal("SQL_CHART"),
  Literal("SQL_SQL_CHART"),
  Literal("SQL_CODE_CHART"),
  Literal("CODE"),
  Literal("SQL"),
  Literal("CODE_CHART"),
);
export const MagicCellChainTemplateTypeLiteral = Union(
  ...MagicCellChainTemplateTypeWithoutChartOnlyLiteral.alternatives,
  Literal("CHART"),
);
export type MagicCellChainTemplateTypeLiteralWithoutChartOnly = Static<
  typeof MagicCellChainTemplateTypeWithoutChartOnlyLiteral
>;
export const MagicCellChainTemplateTypeWithoutChartOnly = getNormalEnum(
  MagicCellChainTemplateTypeWithoutChartOnlyLiteral,
);

export type MagicCellChainTemplateType = Static<
  typeof MagicCellChainTemplateTypeLiteral
>;
export const MagicCellChainTemplateType = getNormalEnum(
  MagicCellChainTemplateTypeLiteral,
);

export const MagicRequestModeLiteral = Union(
  Literal("GENERATE"),
  Literal("EDIT"),
  Literal("MORE_CELLS"),
  Literal("WRITEBACK"),
  Literal("MARKDOWN"),
  Literal("INPUT"),
  Literal("DATA_READ"),
  Literal("PREV_CELL_CODE"),
  Literal("QUESTION"),
);
export type MagicRequestMode = Static<typeof MagicRequestModeLiteral>;
export const MagicRequestMode = getNormalEnum(MagicRequestModeLiteral);

// Magic cell types that the LLM agent is aware of
export const AgentMagicCellTypeLiteral = Union(
  Literal(CellType.CODE),
  Literal(CellType.MARKDOWN),
  Literal(CellType.SQL),
  Literal(CellType.CHART),
  Literal(CellType.EXPLORE),
);

export const AgentMagicCellType = getNormalEnum(AgentMagicCellTypeLiteral);
export type AgentMagicCellType = Static<typeof AgentMagicCellTypeLiteral>;

// Magic cell types that only Hex knows about
export const HexMagicCellTypeLiteral = Union(
  Literal(CellType.TEXT),
  Literal(CellType.METRIC),
  Literal(CellType.EXPLORE),
);
export const HexMagicCellType = getNormalEnum(HexMagicCellTypeLiteral);
export type HexMagicCellType = Static<typeof HexMagicCellTypeLiteral>;

export const MagicCellTypeLiteral = Union(
  // eslint-disable-next-line tree-shaking/no-side-effects-in-initialization
  ...AgentMagicCellTypeLiteral.alternatives,
  // eslint-disable-next-line tree-shaking/no-side-effects-in-initialization
  ...HexMagicCellTypeLiteral.alternatives,
);
export const MagicCellType = getNormalEnum(MagicCellTypeLiteral);
export type MagicCellType = Static<typeof MagicCellTypeLiteral>;

export const MagicNonStreamingCellType = Union(Literal(MagicCellType.CHART));
export type MagicNonStreamingCellType = Static<
  typeof MagicNonStreamingCellType
>;
export const MagicStreamingCellType = Union(
  Literal(MagicCellType.MARKDOWN),
  Literal(MagicCellType.CODE),
  Literal(MagicCellType.SQL),
);
export type MagicStreamingCellType = Static<typeof MagicStreamingCellType>;

export const MagicEventSourceLiteral = Union(
  Literal("CELL"),
  Literal("PROJECT"),
  Literal("EVAL"),
  Literal("EMPTY_PROJECT"),
  Literal("UNSUPPORTED_PAUSE"), // when magic pauses saying "I can't do that", and the user types in a new prompt
  Literal("ADD_CELLS"),
  Literal("CONTINUE_FROM_CHAIN"),
  Literal("HOME"),
  Literal("TR_REGEN"),
  Literal("EXPLORE"),
  Literal("EXPLORE_FROM_HERE"),
);
export type MagicEventSource = Static<typeof MagicEventSourceLiteral>;
export const MagicEventSource = getNormalEnum(MagicEventSourceLiteral);

// TODO(MAGIC-REFACTOR): Deprecate this in favor of more accurate/granular MagicKeyword type
export const SmartEditTypeLiteral = Union(
  Literal("FIX_ERRORS"),
  Literal("DOCUMENT"),
  Literal("CUSTOM"),
  Literal("FIX_SPELLING_AND_GRAMMAR"),
  Literal("INSERT"),
  Literal("NAME_CELL"),
  Literal("SPLIT_CTES"),
  Literal("DESCRIBE_PROJECT"),
  Literal("NAME_PROJECT"),
);
export type SmartEditType = Static<typeof SmartEditTypeLiteral>;
export const SmartEditType = getNormalEnum(SmartEditTypeLiteral);

export const MagicCellEditWithInputLiteral = Union(
  Literal("CUSTOM"),
  Literal("INSERT"),
  Literal("COMPLETION"),
);
export type MagicCellEditWithInput = Static<
  typeof MagicCellEditWithInputLiteral
>;
export const MagicCellEditWithInput = getNormalEnum(
  MagicCellEditWithInputLiteral,
);

export const MagicCellEditWithoutInputLiteral = Union(
  Literal("FIX_ERRORS"),
  Literal("BASIC_SYNTAX_FIX"),
  Literal("DOCUMENT"),
  Literal("FIX_SPELLING_AND_GRAMMAR"),
);
export type MagicCellEditWithoutInput = Static<
  typeof MagicCellEditWithoutInputLiteral
>;
export const MagicCellEditWithoutInput = getNormalEnum(
  MagicCellEditWithoutInputLiteral,
);

export const MagicCellEditTypeLiteral = Union(
  // eslint-disable-next-line tree-shaking/no-side-effects-in-initialization
  ...MagicCellEditWithInputLiteral.alternatives,
  // eslint-disable-next-line tree-shaking/no-side-effects-in-initialization
  ...MagicCellEditWithoutInputLiteral.alternatives,
);
export type MagicCellEditType = Static<typeof MagicCellEditTypeLiteral>;
export const MagicCellEditType = getNormalEnum(MagicCellEditTypeLiteral);

export const MagicCellActionLiteral = Union(
  Literal("NAME_CELL"),
  Literal("SPLIT_CTES"),
  Literal("COMPLETION"),
);
export type MagicCellAction = Static<typeof MagicCellActionLiteral>;
export const MagicCellAction = getNormalEnum(MagicCellActionLiteral);
export const MagicProjectActionLiteral = Union(
  Literal("DESCRIBE_PROJECT"),
  Literal("NAME_PROJECT"),
  Literal("INFER_CHAIN_TEMPLATE"),
  Literal("PLAN_CELL_CHAIN_V2"),
);
export type MagicProjectAction = Static<typeof MagicProjectActionLiteral>;
export const MagicProjectAction = getNormalEnum(MagicProjectActionLiteral);
export const MagicSystemActionLiteral = Union(
  Literal("HYDE_PROJECT"),
  Literal("GENERATE_EXAMPLE_PROMPTS"),
);
export type MagicSystemAction = Static<typeof MagicSystemActionLiteral>;
export const MagicSystemAction = getNormalEnum(MagicSystemActionLiteral);

export const MagicKeywordLiteral = Union(
  Literal("EXPERIMENT"),
  Literal("EXPLORE"),
  Literal("SUMMARIZE_CHAIN"),
  Literal("SUMMARIZE_CELL"),
  Literal("INFER_EXPLORE_INTENT"),
  // eslint-disable-next-line tree-shaking/no-side-effects-in-initialization
  ...MagicCellEditTypeLiteral.alternatives,
  // eslint-disable-next-line tree-shaking/no-side-effects-in-initialization
  ...MagicCellActionLiteral.alternatives,
  // eslint-disable-next-line tree-shaking/no-side-effects-in-initialization
  ...MagicProjectActionLiteral.alternatives,
  // eslint-disable-next-line tree-shaking/no-side-effects-in-initialization
  ...MagicSystemActionLiteral.alternatives,
);
export type MagicKeyword = Static<typeof MagicKeywordLiteral>;
export const MagicKeyword = getNormalEnum(MagicKeywordLiteral);

// Magic keywords that should not be reflected as a `latestMagicEvent` for a cell
export const ExcludedCellMagicKeywordLiteral = Union(
  Literal(MagicKeyword.NAME_CELL),
  Literal(MagicKeyword.SUMMARIZE_CHAIN),
  Literal(MagicKeyword.SUMMARIZE_CELL),
  Literal(MagicKeyword.INFER_EXPLORE_INTENT),
);
export type ExcludedCellMagicKeyword = Static<
  typeof ExcludedCellMagicKeywordLiteral
>;
export const ExcludedCellMagicKeyword = getNormalEnum(
  ExcludedCellMagicKeywordLiteral,
);
export const SystemMagicEventStatusLiteral = Union(
  Literal("CREATED"),
  Literal("PROCESSING"),
  Literal("FAILED"),
  Literal("COMPLETED"),
);
export type SystemMagicEventStatus = Static<
  typeof SystemMagicEventStatusLiteral
>;
export const SystemMagicEventStatus = getNormalEnum(
  SystemMagicEventStatusLiteral,
);

export const SystemMagicEventSourceLiteral = Union(
  Literal("SERVER"),
  Literal("EVAL"),
);
export type SystemMagicEventSource = Static<
  typeof SystemMagicEventSourceLiteral
>;
export const SystemMagicEventSource = getNormalEnum(
  SystemMagicEventSourceLiteral,
);

export const MagicEventStatusLiteral = Union(
  Literal("LOADING"),
  Literal("ERROR"),
  Literal("PENDING_REVIEW"),
  Literal("CANCELLED"),
  Literal("REVIEWED"),
);
export type MagicEventStatus = Static<typeof MagicEventStatusLiteral>;
export const MagicEventStatus = getNormalEnum(MagicEventStatusLiteral);

// Special failure events that we show as warnings
// in the UI because a partial result was still achieved.
export const MagicEventWarningLiteral = Union(
  Literal("UNSUPPORTED_QUERY_ELEMENTS"), // agent wrote SQL for an explore that cannot be expressed in explore UX
  Literal("JOIN_VALIDATION_TIMEOUT"),
);

export const MagicEventFailureLiteral = Union(
  Literal("NO_CONTEXT"),
  Literal("BAD_DATA"),
  Literal("AGENT_ERROR"),
  Literal("AGENT_OVERLOADED"),
  Literal("GENERIC_ERROR"),
  Literal("POSSIBLE_PROMPT_INJECTION"),
  Literal("OVER_LIMIT"),
  Literal("NO_DATA"),
  Literal("INVALID_RESPONSE"),
  Literal("HALLUCINATED_SCHEMA_ELEMENT"),
  Literal("NON_EXPRESSIBLE"),
  Literal("INVALID_JOIN"),
  Literal("EXPLORE_TIMED_OUT"),
);

export const MagicEventFailureReasonLiteral = Union(
  ...MagicEventFailureLiteral.alternatives,
  ...MagicEventWarningLiteral.alternatives,
);

export type MagicEventFailureReason = Static<
  typeof MagicEventFailureReasonLiteral
>;
export const MagicEventFailureReason = getNormalEnum(
  MagicEventFailureReasonLiteral,
);

export const MagicCellRunResultLiteral = Union(
  Literal("SUCCESS"), // cell ran successfully
  Literal("ERROR"), // cell execution errored
  Literal("TIMEOUT"), // cell took too long to run
  Literal("CANCELLED"), // cell execution was cancelled (e.g. kernel interrupt)
  Literal("NO_DATA"), // sql cell returned no data
  Literal("FAILURE"), // failure that should cause the whole MA to error (e.g. no context)
);
export type MagicCellRunResult = Static<typeof MagicCellRunResultLiteral>;
export const MagicCellRunResult = getNormalEnum(MagicCellRunResultLiteral);

export const TEXT_TOO_LONG_ERROR =
  "Magic only supports up to ~3,000 words. Try splitting this cell into multiple cells.";

export const MODEL_OVERLOADED_ERROR =
  "Our model provider is overloaded right now. Magic will be back as soon as they are!";

export const MAGIC_AGENT_ERROR =
  "Our model provider appears to be having problems. Magic will be back as soon as they are!";

export const GENERIC_MAGIC_ERROR =
  "Uh oh! Something unexpected went wrong. If you're seeing this, please let us know at magic@hex.tech.";

export const MAGIC_BAD_PROMPT_ERROR =
  "The provided prompt is invalid. Please modify your request and try again.";

export const MAGIC_OVER_USAGE_ERROR =
  "You've exceeded your Magic usage limit. Please upgrade your plan to continue using Magic.";

export const MAGIC_NO_CONTEXT_ERROR =
  "Magic couldn't find any relevant data. Please try refining your request.";

export const MAGIC_NO_RESULTS =
  "SQL cell returned no data. Please review the results before resuming.";

export const MAGIC_BAD_DATA =
  "Magic isn't sure what data to use for this chart.";

export const MAGIC_INVALID_RESPONSE =
  "Magic wasn't able to generate a valid response. Please modify your prompt and try again.";

export const MAGIC_UNSUPPORTED_QUERY_ELEMENTS =
  "The provided request cannot be expressed in an Exploration.";

const MAGIC_JOIN_VALIDATION_ERROR =
  "Magic encountered an error trying to join tables. Double check your data or edit the join.";

const MAGIC_JOIN_VALIDATION_TIMEOUT =
  "Magic is unable to validate this join which may lead to incorrect results, double check your data.";

const MAGIC_NO_SUMMARY_FROM_EXPLORE_TIMEOUT =
  "Your exploration took too long to run. Magic was unable to generate a summary.";

export const MagicErrorTypeMessageMap: Record<MagicEventFailureReason, string> =
  {
    [MagicEventFailureReason.AGENT_OVERLOADED]: MODEL_OVERLOADED_ERROR,
    [MagicEventFailureReason.GENERIC_ERROR]: GENERIC_MAGIC_ERROR,
    [MagicEventFailureReason.POSSIBLE_PROMPT_INJECTION]: MAGIC_BAD_PROMPT_ERROR,
    [MagicEventFailureReason.AGENT_ERROR]: MAGIC_AGENT_ERROR,
    [MagicEventFailureReason.NO_CONTEXT]: MAGIC_NO_CONTEXT_ERROR,
    [MagicEventFailureReason.OVER_LIMIT]: MAGIC_OVER_USAGE_ERROR,
    [MagicEventFailureReason.NO_DATA]: MAGIC_NO_RESULTS,
    [MagicEventFailureReason.BAD_DATA]: MAGIC_BAD_DATA,
    [MagicEventFailureReason.INVALID_RESPONSE]: MAGIC_INVALID_RESPONSE,
    [MagicEventFailureReason.UNSUPPORTED_QUERY_ELEMENTS]:
      MAGIC_UNSUPPORTED_QUERY_ELEMENTS,
    [MagicEventFailureReason.NON_EXPRESSIBLE]: MAGIC_UNSUPPORTED_QUERY_ELEMENTS,
    [MagicEventFailureReason.HALLUCINATED_SCHEMA_ELEMENT]: GENERIC_MAGIC_ERROR, // Should never be shown to the user
    [MagicEventFailureReason.JOIN_VALIDATION_TIMEOUT]:
      MAGIC_JOIN_VALIDATION_TIMEOUT,
    [MagicEventFailureReason.INVALID_JOIN]: MAGIC_JOIN_VALIDATION_ERROR,
    [MagicEventFailureReason.EXPLORE_TIMED_OUT]:
      MAGIC_NO_SUMMARY_FROM_EXPLORE_TIMEOUT,
  };
export const MagicTableReference = RRecord({
  db: String.Or(Null),
  schema: String,
  table: String,
});
export type MagicTableReference = Static<typeof MagicTableReference>;

export const MagicExploreTableInfo = RRecord({
  baseTable: MagicTableReference,
  joinTables: Array(MagicTableReference),
});
export type MagicExploreTableInfo = Static<typeof MagicExploreTableInfo>;

export const MagicHydeQuestion = String;
export type MagicHydeQuestion = Static<typeof MagicHydeQuestion>;

// NB - this is part of the function payload sent to the LLM; use caution if modifying,
// as it could affect response quality
export const MentionedDataframeNameSchema = Array(
  RRecord({ name: String, type: String }),
);
export type MentionedDataframeNameSchema = Static<
  typeof MentionedDataframeNameSchema
>;

export const VariableSpec = RRecord({
  name: String,
  type: String,
  dataframeSchema: Optional(MentionedDataframeNameSchema),
});
export type VariableSpec = Static<typeof VariableSpec>;
export type VariableMap = Record<string, VariableSpec>;

export const MagicPromptGenerationCellSpec = RRecord({
  type: MagicCellTypeLiteral,
  cellTitle: String,
  inputVariables: Array(VariableSpec),
  outputVariables: Array(VariableSpec),
  prompt: String,
});

export type MagicPromptGenerationCellSpec = Static<
  typeof MagicPromptGenerationCellSpec
>;

export const RealMagicAgentTypeLiteral = Union(
  Literal("OPENAI"),
  Literal("ANTHROPIC"),
);
export type RealMagicAgentType = Static<typeof RealMagicAgentTypeLiteral>;
export const RealMagicAgentType = getNormalEnum(RealMagicAgentTypeLiteral);

export const MagicAgentTypeLiteral = Union(
  ...RealMagicAgentTypeLiteral.alternatives,
  Literal("MOCK"),
);
export type MagicAgentType = Static<typeof MagicAgentTypeLiteral>;
export const MagicAgentType = getNormalEnum(MagicAgentTypeLiteral);

export const StreamingKey = Union(
  RRecord({
    key: String,
    type: Literal("STRING"),
  }),
  RRecord({ key: Array(String), type: Literal("MULTI_OBJECT") }),
  RRecord({
    key: String,
    type: Literal("OBJECT_ARRAY"),
  }),
);
export type StreamingKey = Static<typeof StreamingKey>;

const StringOrFunction = String.Or(
  RRecord({
    streamingKey: Optional(StreamingKey),
    function: Dictionary(Unknown, String),
  }),
);
export const StringOrFunctionOrFailureType = Union(
  StringOrFunction,
  MagicEventFailureReasonLiteral,
);
export const MockAgentModelParams = RRecord({
  model: Union(Literal("MOCK_PRIMARY_MODEL"), Literal("MOCK_FALLBACK_MODEL")),
  sleepTime: Optional(Number),
  responseMap: Dictionary(
    Optional(
      Union(
        StringOrFunctionOrFailureType,
        Dictionary(StringOrFunctionOrFailureType, String),
      ),
    ),
    PromptTemplateTypeLiteral,
  ),
});

export type MockAgentModelParams = Static<typeof MockAgentModelParams>;

export const MockAgentConfig = RRecord({
  agent: Literal(MagicAgentType.MOCK),
  params: MockAgentModelParams,
  featureFlag: Optional(AppFeatureFlagRuntype),
});
export type MockAgentConfig = Static<typeof MockAgentConfig>;

export const OpenAiAgentConfig = RRecord({
  agent: Literal(MagicAgentType.OPENAI),
  params: OpenAiModelParams,
  featureFlag: Optional(AppFeatureFlagRuntype),
});
export type OpenAiAgentConfig = Static<typeof OpenAiAgentConfig>;

export const AnthropicAgentConfig = RRecord({
  agent: Literal(MagicAgentType.ANTHROPIC),
  params: AnthropicModelParams,
  featureFlag: Optional(AppFeatureFlagRuntype),
});
export type AnthropicAgentConfig = Static<typeof AnthropicAgentConfig>;

export const MagicAgentConfig = Union(
  OpenAiAgentConfig,
  AnthropicAgentConfig,
  MockAgentConfig,
);
export type MagicAgentConfig = Static<typeof MagicAgentConfig>;

export const MagicConfig = RRecord({
  primaryConfig: MagicAgentConfig,
  fallbackConfig: MagicAgentConfig,
});
export type MagicConfig = Static<typeof MagicConfig>;
export const OpenAiApiConfig = RRecord({
  organization: String.optional(),
  apiKey: String,
  orgApiKey: String.optional(),
});
export type OpenAiApiConfig = Static<typeof OpenAiApiConfig>;
export const MagicApiConfig = Union(OpenAiApiConfig);
export type MagicApiConfig = Static<typeof MagicApiConfig>;

export const MagicTypeAheadProviderConfig = RRecord({
  provider: MagicTypeAheadProviderLiteral,
  config: Union(AugmentModelConfig, CodeiumModelConfig),
});
export type MagicTypeAheadProviderConfig = Static<
  typeof MagicTypeAheadProviderConfig
>;

export const MagicAcceptTypeLiteral = Union(
  Literal("KEEP"),
  Literal("KEEP_AND_RUN"),
  Literal("KEEP_IN_NEW_CELL"),
  Literal("KEEP_AND_RUN_IN_NEW_CELL"),
  Literal("KEEP_AND_EDIT"),
  Literal("REJECT"),
);
export type MagicAcceptType = Static<typeof MagicAcceptTypeLiteral>;
export const MagicAcceptType = getNormalEnum(MagicAcceptTypeLiteral);

export const MagicCancelReasonLiteral = Union(
  Literal("MANUAL"),
  Literal("GEN_CODE_ERROR"),
  Literal("TIMEOUT"),
  Literal("NO_DATA"),
  Literal("UNSUPPORTED"),
  Literal("DEFLECTION"),
);
export type MagicCancelReason = Static<typeof MagicCancelReasonLiteral>;
export const MagicCancelReason = getNormalEnum(MagicCancelReasonLiteral);

export const MagicFeedbackTypeLiteral = Union(
  Literal("GENERATION"),
  Literal("DEFLECTION"),
);
export type MagicFeedbackType = Static<typeof MagicFeedbackTypeLiteral>;
export const MagicFeedbackType = getNormalEnum(MagicFeedbackTypeLiteral);

export const ColumnReference = RRecord({
  name: String,
  type: String,
  description: String.Or(Null),
  dataManagerDescription: Optional(String),
  vectorDistance: Optional(Number),
});
export type ColumnReference = Static<typeof ColumnReference>;

export const MagicDataframeReferenceTypeLiteral = Union(
  Literal("DATAFRAME"),
  Literal("SNOWPARK_DATAFRAME"),
  Literal("REMOTE_DATAFRAME"),
  Literal("BIGFRAMES_DATAFRAME"),
);
export const MagicDataframeReferenceType = getNormalEnum(
  MagicDataframeReferenceTypeLiteral,
);

export const MagicTableReferenceTypeLiteral = Literal("TABLE");
export const MagicSemanticDatasetReferenceTypeLiteral =
  Literal("SEMANTIC_DATASET");

export const MagicReferenceTypeLiteral = Union(
  ...MagicDataframeReferenceTypeLiteral.alternatives,
  MagicSemanticDatasetReferenceTypeLiteral,
  MagicTableReferenceTypeLiteral,
);
export const MagicReferenceType = getNormalEnum(MagicReferenceTypeLiteral);
export type MagicReferenceType = Static<typeof MagicReferenceTypeLiteral>;

export const ReferenceInfo = RRecord({
  dataConnectionId: Optional(DataConnectionId),
  table: String,
  type: MagicReferenceTypeLiteral,
  tableId: Optional(DataSourceTableId),
  tableDescription: String.Or(Null),
  schema: String,
  database: String.Or(Null),
  columns: Array(ColumnReference),
  dataManagerDescription: Optional(String),
  tablePrioritized: Boolean.Or(Null),
  schemaPrioritized: Boolean.Or(Null),
  databasePrioritized: Boolean.Or(Null),
  formatted: Optional(String),
});

export type ReferenceInfo = Static<typeof ReferenceInfo>;

export const MagicClientReferenceInfo = Union(
  RRecord({ id: DataSourceTableId, type: MagicTableReferenceTypeLiteral }),
  RRecord({
    name: String,
    type: MagicDataframeReferenceTypeLiteral,
  }),
);

export type MagicClientReferenceInfo = Static<typeof MagicClientReferenceInfo>;

export const CELL_TYPE_BY_CHAIN_TEMPLATE_TYPE: Record<
  MagicCellChainTemplateType,
  MagicCellType[]
> = {
  [MagicCellChainTemplateType.SQL_CHART]: [
    MagicCellType.SQL,
    MagicCellType.CHART,
  ],
  [MagicCellChainTemplateType.SQL_SQL_CHART]: [
    MagicCellType.SQL,
    MagicCellType.SQL,
    MagicCellType.CHART,
  ],
  [MagicCellChainTemplateType.SQL_CODE_CHART]: [
    MagicCellType.SQL,
    MagicCellType.CODE,
    MagicCellType.CHART,
  ],
  [MagicCellChainTemplateType.CODE_CHART]: [
    MagicCellType.CODE,
    MagicCellType.CHART,
  ],
  [MagicCellChainTemplateType.CODE]: [MagicCellType.CODE],
  [MagicCellChainTemplateType.CHART]: [MagicCellType.CHART],
  [MagicCellChainTemplateType.SQL]: [MagicCellType.SQL],
};

export const GENERATION_PROMPT_TEMPLATE_BY_CELL_TYPE = {
  [MagicCellType.SQL]: PromptTemplateType.GENERATE_SQL,
  [MagicCellType.CODE]: PromptTemplateType.GENERATE_PYTHON,
  [MagicCellType.CHART]: PromptTemplateType.GENERATE_CHART,
};

export const CELL_TYPE_BY_PROMPT_TEMPLATE = {
  [PromptTemplateType.GENERATE_SQL]: MagicCellType.SQL,
  [PromptTemplateType.GENERATE_PYTHON]: MagicCellType.CODE,
  [PromptTemplateType.GENERATE_CHART]: MagicCellType.CHART,
  [PromptTemplateType.SIMPLE_CHART]: MagicCellType.CHART,
};

// GeneratePromptComponentsOptions is used to generate the project context for a Magic Event
export const GeneratePromptComponentsOptions = RRecord({
  previousPromptIds: Array(MagicEventId), // Ids of previous prompts in the project
  existingChainCellIds: Array(CellId), // Ids of existing cells in the current chain to be generated
  traceback: String.Or(Null), // Traceback of the current cell
  contentToEdit: String.Or(Null), // alternative text/code to edit (i.e. not the existing cell source); used for e.g. diff-based-editing autofixes
});

export type GeneratePromptComponentsOptions = Static<
  typeof GeneratePromptComponentsOptions
>;

export const MagicCellContentsReferences = RRecord({
  tableReferences: Array(String),
});
export type MagicCellContentsReferences = Static<
  typeof MagicCellContentsReferences
>;

export const MagicLiteralType = Union(
  Literal("STRING"),
  Literal("FLOAT"),
  Literal("INTEGER"),
  Literal("BOOLEAN"),
  Literal("DATETIME"),
);
export type MagicLiteralType = Static<typeof MagicLiteralType>;

export const MagicExploreColumnRef = RRecord({
  column: String,
  db: String.Or(Null),
  // schema and table should never be null for new explore generations, but are nullable for
  // backcompat with previously generated suggested prompts
  schema: String.Or(Null),
  table: String.Or(Null),
});
export type MagicExploreColumnRef = Static<typeof MagicExploreColumnRef>;

export const MagicExploreStubField = RRecord({
  columns: Array(MagicExploreColumnRef), // Array of column names that this field depends on
  alias: String.Or(Null), // Alias for the field, if any
  identifier: String, // The raw identifier for the field (either a calc expression, column name, literal, or "*")
  type: MagicLiteralType.Or(Null), // The type of the field, if it's a literal
  agg: HqlAggregationFunctionLiteral.Or(Null), // The aggregation to apply to the field in the explore spec
  calc: Boolean, // Whether the field is a calculated field
});

export type MagicExploreStubField = Static<typeof MagicExploreStubField>;

export const UnconsumedQueryElementCategoryLiteral = Union(
  Literal("JOIN"),
  Literal("FILTER"),
  Literal("SELECT"),
  Literal("ORDER"),
  Literal("ROOT"),
  Literal("WINDOW"),
);
export type UnconsumedQueryElementCategory = Static<
  typeof UnconsumedQueryElementCategoryLiteral
>;
export const UnconsumedQueryElementCategory = getNormalEnum(
  UnconsumedQueryElementCategoryLiteral,
);

export const MagicExploreRelativeDateField = RRecord({
  amount: Number,
  unit: RelativeDateUnitLiteral,
  operation: RelativeDateOperationLiteral,
  column: MagicExploreColumnRef,
  calc: Literal(false),
});
export type MagicExploreRelativeDateField = Static<
  typeof MagicExploreRelativeDateField
>;

export const UnconsumedQueryElements = Dictionary(
  Array(String),
  UnconsumedQueryElementCategoryLiteral,
);
export type UnconsumedQueryElements = Static<typeof UnconsumedQueryElements>;

export const MagicExploreStubFilterPredicate = Union(
  ListBinaryColumnPredicateOpLiteral,
  BinaryColumnPredicateOpLiteral,
  UnaryColumnPredicateOpLiteral,
);

export const MagicExploreSqlParseOrderBy = RRecord({
  by: Array(MagicExploreStubField),
  desc: Boolean,
}).Or(Null);

export type MagicExploreSqlParseOrderBy = Static<
  typeof MagicExploreSqlParseOrderBy
>;

export const MagicExploreStubFilters = Array(
  RRecord({
    predicate: MagicExploreStubFilterPredicate,
    a: Union(MagicExploreStubField, MagicExploreRelativeDateField),
    b: Union(Array(MagicExploreStubField), MagicExploreStubField, Null),
  }),
);
export type MagicExploreStubFilters = Static<typeof MagicExploreStubFilters>;

export const MagicExploreStubJoins = Array(
  RRecord({
    baseTable: MagicTableReference,
    table: MagicTableReference,
    on: RRecord({
      a: MagicExploreStubField,
      b: MagicExploreStubField,
    }),
  }),
).Or(Null);

export type MagicExploreStubJoins = Static<typeof MagicExploreStubJoins>;

export const MagicExploreSqlParse = RRecord({
  // All fields selected in the query
  fields: Array(MagicExploreStubField),
  // Unconsumed query elements, grouped by category If non-empty, indicates the agent response is
  // not expressible in the explore UX
  unconsumedQueryElements: UnconsumedQueryElements,
  // Array of AND-ed filters in the query. Because the explore UX does not support OR filters,
  // those are represented as calculated fields + a simple IS_TRUE filter on the calculated column.
  filters: MagicExploreStubFilters,
  // The table the agent selected from in the query
  table: MagicTableReference,
  // The limit clause in the query --> Not currently used
  limit: Number.Or(Null),
  // Specification of the fields to order by in the query
  order: MagicExploreSqlParseOrderBy,
  joins: MagicExploreStubJoins,
});
export type MagicExploreSqlParse = Static<typeof MagicExploreSqlParse>;

export const UserMagicConfig = RRecord({
  overMagicUsageLimit: Boolean,
  magicEnabled: Boolean,
  magicTypeaheadAllowed: Boolean,
  onboardedToMagic: Boolean,
  onboardedToMagicTypeahead: Boolean,
  /** If typeahead has been configured for the stack (e.g., Codeium API key has been set) */
  typeaheadConfigured: Boolean,
});
export type UserMagicConfig = Static<typeof UserMagicConfig>;

// ProjectContextForPromptGeneration is the context used to generate Prompt Components for a Magic Event
export const ProjectContextForPromptGeneration = RRecord({
  previousPrompts: Array(
    RRecord({ prompt: String, referencedTables: Array(MagicTableReference) }),
  ), // Previous prompts in the project
  existingChainCells: Array(MagicPromptGenerationCellSpec), // Existing cells in the current chain to be generated
  projectCategories: Array(
    RRecord({ name: String, description: String.Or(Null) }),
  ), // Categories of the project
  projectCellSources: Array(
    RRecord({
      contents: String,
      title: String,
    }),
  ), // Contents and title of each cell in the project
  projectTitle: String, // Title of the project
  projectVariables: Array(VariableName), // Existing in-scope variables for the project that the agent should not overwrite in its generated code
  projectImports: Array(String), // Existing imports in the project that the agent should not include in its generated code
  previousEdits: Array(
    RRecord({
      prompt: String,
      result: String,
    }),
  ),
  previousExploreEdits: Array(
    RRecord({
      prompt: String,
      result: String,
      type: MagicKeywordLiteral,
    }),
  ),
  projectStatus: RRecord({
    name: String,
    description: String.Or(Null),
  }),
  chainContents: Array(
    RRecord({ cellType: MagicCellTypeLiteral, contents: String }),
  ),
  lastCellOutput: DisplayTableOutput,
  targetCellContents: String,
  exploreOutputs: RRecord({
    table: Optional(DisplayTableOutput),
    rowCount: Optional(Number),
    pageSize: Optional(Number),
  }),
  exploreConfig: RRecord({
    sqlQuery: Optional(String), // Raw SQL query string the model generated.
    sqlParse: Optional(MagicExploreSqlParse), // Query string parsed to "magic stub" schema.
  }),
});

export type ProjectContextForPromptGeneration = Static<
  typeof ProjectContextForPromptGeneration
>;

/**
 * Note that we don't actually use the presentation property in the diffs
 * since it's pretty obvious what presentation explore changed to BUT I'm including
 * it here since it *seems* like it should be diffed and I don't want it to be mistakenly
 * added down the line.
 */
export type DiffableProperties = "presentation" | keyof MagicExploreSqlParse;

export const MagicExploreTableDiff = RRecord({
  property: Literal("table"),
  before: ExploreCellDataframe.Or(Null),
  after: ExploreCellDataframe.Or(Null),
});
export type MagicExploreTableDiff = Static<typeof MagicExploreTableDiff>;

export const MagicExploreFiltersDiff = RRecord({
  property: Literal("filters"),
  before: TableFiltersDefinition.Or(Null),
  after: TableFiltersDefinition.Or(Null),
});
export type MagicExploreFiltersDiff = Static<typeof MagicExploreFiltersDiff>;

export const SortDiffValue = RRecord({
  direction: SortDirectionLiteral,
  column: String,
});
export type SortDiffValue = Static<typeof SortDiffValue>;

export const MagicExploreOrderDiff = RRecord({
  property: Literal("order"),
  before: SortDiffValue.Or(Null),
  after: SortDiffValue.Or(Null),
});
export type MagicExploreOrderDiff = Static<typeof MagicExploreOrderDiff>;

export const MagicExploreFieldsDiff = RRecord({
  property: Literal("fields"),
  before: RRecord({
    fields: Array(ExploreField),
    details: Array(ExploreDetailField),
    chartConfig: ExploreChartConfig,
  }).Or(Null),
  after: RRecord({
    fields: Array(ExploreField),
    details: Array(ExploreDetailField),
    chartConfig: ExploreChartConfig,
  }),
});
export type MagicExploreFieldsDiff = Static<typeof MagicExploreFieldsDiff>;

export const MagicExploreJoinsDiff = RRecord({
  property: Literal("joins"),
  before: Array(String).Or(Null),
  after: Array(String).Or(Null),
});
export type MagicExploreJoinsDiff = Static<typeof MagicExploreJoinsDiff>;

export const MagicExploreDiff = Union(
  MagicExploreTableDiff,
  MagicExploreFiltersDiff,
  MagicExploreOrderDiff,
  MagicExploreFieldsDiff,
  MagicExploreJoinsDiff,
);
export type MagicExploreDiff = Static<typeof MagicExploreDiff>;
